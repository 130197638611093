import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import { Link } from 'gatsby'
import Header from '../../components/header'
import CharacterGrid from '../../components/charactergrid'
import InBrief from '../../components/inbrief'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageRight } from '../../components/imageelements'
import { SynopsisAct } from '../../components/synposisstyling'
import Bibliography from '../../components/bibliography.js'
import { Helmet } from "react-helmet"
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import VideoPlayer from '../../components/videoplayer'

const characters = [
  {name: 'Count Almaviva', voice: 'Baritone', desc: 'Local pol who believes he has the right to any woman in his realm. Currently has designs on his wife’s maid, Susanna.'},
  {name: 'Countess Almaviva', voice: 'Soprano', desc: 'Still loves the Count, despite him trying to cheat on her with her maid. (You can do better, Countess!)'},
  {name: 'Susanna', voice: 'Soprano', desc: 'The Countess’s maid. Engaged to Figaro and wooed by the Count.'},
  {name: 'Figaro', voice: 'Bass', desc: 'The Count’s valet. Generally affable defender of his fiancée’s honour, but has some ridiculous ideas about women in general.'},
  {name: 'Cherubino', voice: 'Mezzo-soprano', desc: 'The Count’s youthful page who is in love with nearly every woman in the opera, but particularly the Countess.'},
  {name: 'Marcellina', voice: 'Soprano', desc: 'Bartolo’s housekeeper who loaned Figaro a substantial amount of money with the assurance that he pay her back or marry her.'},
  {name: 'Bartolo', voice: 'Bass', desc: 'Doctor from Seville who is on Marcellina’s side when it comes to loan contracts (and other things it turns out).'},
  {name: 'Don Basilio', voice: 'Tenor', desc: 'The Countess’s music teacher who is also doing his best to get her to succumb to the Count’s advances.'},
  {name: 'Barbarina', voice: 'Soprano', desc: 'Antonio’s daughter, Susanna’s niece, and the one woman who seemingly really loves Cherubino.'},
  {name: 'Antonio', voice: 'Bass', desc: 'The Count’s gardener, Susanna’s uncle, and a close observer of castle windows.'},
];

const inbrief = {name: 'Le nozze di Figaro', 'translatedname': 'The Marriage of Figaro', composer: "Wolfgang Amadeus Mozart", librettist: "Lorenzo da Ponte", language: "Italian", date: 'May 1st, 1786', acts: "Four", musiclength: "three hours"};

const bibliography = [
  {name: 'Mozart The Reign of Love', writer: 'Jan Swafford', url: 'https://amzn.to/2T2fLIO'},
  {name: 'Stories of the Great Operas & their Composers', writer: 'Ernest Newman', url: 'https://amzn.to/2T3IjBt'},
  {name: 'The Figaro Trilogy: The Barber of Seville, The Marriage of Figaro, The Guilty Mother', writer: 'Beaumarchais', url: 'https://amzn.to/3lZjmUk'},
  {name: 'BBC Music Magazine survey of greatest operas', writer: 'BBC Music Magazine', url: 'https://www.classical-music.com/news/mozart-s-marriage-figaro-voted-greatest-opera-all-time/'},
]

// markup
const FigaroPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>The Marriage of Figaro | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operas/figaro" />
          <meta name="description" content="A guide to Mozart's masterpiece, The Marriage of Figaro (Le nozze di Figaro). Including Synopsis, Music & Arias, Fun Facts, Running Time and much more!" />
        </Helmet>
        <Hero title="The Marriage of Figaro" subtitle="An Opera by Wolfgang Amadeus Mozart"/>
        <MainBodySection>

          <p><i>Le nozze di Figaro</i> is the product of Mozart's first collaboration with the librettist Lorenzo Da Ponte, a relationship that resulted in three of the greatest operas of all time—<i>Figaro</i>, <Link to="/operas/giovanni">Don Giovanni</Link>, and <i>Così fan tutte</i>. <i>Figaro</i> is an opera buffa, essentially a farce, built on mistaken identities and misunderstandings centred around the upstairs/downstairs power dynamics of so many classic comedies. The opera's first half is perfect, with loads of laughs and memorable solos, duets, and trios propelling the story to an ensemble finale that might be the best Mozart wrote. The increasingly intricate plot machinations slow the momentum in the third act when lots of recitative (unsung dialogue) is required to try and justify the twists. Still, it is all worth it for the opera's conclusion, where Mozart does what only Mozart can do, marrying the madcap and the divine before bursting joyously into celebration.</p>

          
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <p>The opera offers many opportunities for individual singers to shine, from Susanna, who gets a duet with every major character; to Figaro, who goes from comic to lout to buffoon to devious; to the Countess, who has a show-stopping aria nearly every time she takes the stage. All in all, it is a rollicking good night at the opera.</p>

          <Header title="In Brief"/>

          <InBrief content={inbrief}/>

          </MainBodySection>

  
          <div id="ezoic-pub-ad-placeholder-103"> </div>


          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>

            <Header title="Characters"/>

            <CharacterGrid rows={characters}/>

            <Header title="Synopsis"/>

            <SynopsisAct>Act I</SynopsisAct>

            <p>The opera opens on Figaro and Susanna. He is measuring the floor of the servants' room to see if a bed will fit; she is trying on a hat she made for her wedding outfit. When she asks what he's doing, Figaro says the Count has generously offered to let them live in the room after they’re married. Susanna wants none of this “generosity,” as the room lies between the Count's on one side, and the Countess's, on the other.</p>

            <p>Figaro can’t understand why his fiancée wouldn’t want to be “two steps“ away from Madame if she rang at night. Susanna replies that she would also be “three jumps“ away from the Count while Figaro runs errands. Figaro is shocked at this slander until Susanna tells him that lately, the Count has tired of chasing skirts far from home and is instead trying his luck at home, specifically with her skirts. Even her singing teacher, Don Basilio, is in on it, urging her to remain open to the advances. The Count, Susanna says, is regretting abolishing *le droit du seigneur* and plans on reinstating it—with her. (This feudal right of a lord to sleep with his subject’s bride on her wedding night was not a thing in Mozart’s time, nor did it likely ever exist as established law.)</p>

            <p>Susanna leaves to help the Countess, and Figaro sings his response to the Count’s plans, “Se vuol ballare“, foreshadowing centuries of machismo. “You want to dance, buddy, ok, let's dance“.</p>

            <VideoPlayer src="https://www.youtube.com/embed/3MdXqtQ1vJQ?autoplay=1" id="3MdXqtQ1vJQ" title="Bryn Terfel sings Figaro’s “Se vuol ballare” at the Met in 1998"/>

            <p>Figaro leaves, and Bartolo and Marcellina enter. She is scheming to win Figaro for herself since he signed a contract saying he would marry her if he didn't repay the money she lent him. Bartolo has agreed to help because he feels that Figaro ruined his chances with the Countess. (<i>Le nozze di Figaro</i> is the second play in a trilogy; in the first, Figaro helps the Count successfully woo Rosina, the current Countess, who was set to marry Bartolo.) Bartolo sings about his sweet, sweet revenge, then exits.</p>

            <p>Susanna returns. She and Marcellina mutter to themselves and then turn their syrupy sweetness on each other in a duet version of “After you. No, I insist, after you,“ that grows increasingly snide. Susanna gets the last word, and Marcellina stomps off as Cherubino arrives.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-104"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <p>The lovesick youth is distraught because the Count found him with Barbarina and will send him away, preventing him from seeing not only Barbarina but also Susanna and especially the Countess. He snatches one of the Countess's ribbons from Susanna, then sings about how different women drive him crazy in different ways.</p>

            <VideoPlayer src="https://www.youtube.com/embed/EdoTSOPbPYQ?autoplay=1" id="EdoTSOPbPYQ" title="Pamela Helen Stephen is girl crazy singing “Non so più cosa son“ from the South Bank Centre in London in summer 1993"/>

            <p>Povero Cherubino's adolescent aching is interrupted by the Count's arrival. Cherubino hides. The Count starts to hit on Susanna, asking her to meet him in the garden at dusk, when Basilio's voice is heard outside, so the Count goes to hide...in the same spot as Cherubino! The youngster slips around the front of a chair, and Susanna throws a dress over him. Basilio is looking for the Count on behalf of Figaro, but Susanna tells him off. Apropos of plot, Basilio mentions that Cherubino should watch the way he stares at the Countess because there will be trouble if the Count sees it! Susanna gets indignant, but it's too late. The Count emerges from hiding and demands an explanation in the trio “Cosa sento“. Susanna nearly faints to distract the men. The Count starts to relate how he found Cherubino with Barbarina the other day and, demonstrating, lifts the dress on the chair and uncovers Cherubino! Again!</p>

            <p>Basilio's joy, the Count's indignation, Cherubino's “I didn't hear anything, I swear!“ and Susanna's pleas for calm are all interrupted by Figaro. He leads in a chorus of peasant girls praising the Count's decision to abolish his right to sleep with them on their wedding nights. A true politician and cad, the Count, accepts their praise while acknowledging to the audience that he has no intention of actually giving up his right.</p>

            <p>The celebration is spoiled by the Count telling Cherubino he is being assigned to an officer's regiment, effective immediately. The Count and Basilio leave. Cherubino says goodbye to Susanna, while Figaro serenades him with the sarcastic martial masterpiece “Non più andrai“. Never again will you chase girls around the castle, Cherubino, you “farfallone amoroso,“ you amorous butterfly!</p>

            <VideoPlayer src="https://www.youtube.com/embed/mRNirc4fiqI?autoplay=1" id="mRNirc4fiqI" title="Joshua Bloom's Figaro sends Marta Fontanals-Simmons's Cherubino off to become an officer, “Non più andrai“ at Garsington in 2017"/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-105"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act II</SynopsisAct>

            <p>We open on the Countess alone in her room, where she soon sings one of the most beautiful arias Mozart wrote, a heart-wrenching prayer that her husband will start to love her again, “Porgi, amor“.</p>

            <p>Susanna, followed closely by Figaro, comes in and makes matters worse by telling the Countess about the Count's desire to spend the night with her. Figaro adds that since Susanna keeps refusing him, the Count threatens to back Marcellina and force him to marry her. But...Figaro has a plan, one that he has already put in motion without asking! He gave Basilio a forged letter that says the Countess herself is meeting a lover tonight, but they will send Cherubino—dressed as Susanna—to meet the Count, and then the Countess can catch him in the act. (I urge you, do not overanalyze Figaro's plan! Just go with it...because things are about to get WAY more complicated.)</p>

            <p>Figaro, quite pleased with himself, leaves to find Cherubino. The youth returns, and the Countess and Susanna urge/force him to sing his love song, “Vuoi che sapete“, that he wrote for the Countess, which he does, blushing and nervous.</p>

            <VideoPlayer src="https://www.youtube.com/embed/M4QfT5sJcJ0?autoplay=1" id="M4QfT5sJcJ0" title="Isabel Leonard as Cherubino sings “Vuoi che sapete“ to Sally Matthew's Countess at Glyndebourne in 2012"/>

            <p>Susanna (sometimes with the Countess's help) starts to undress and re-dress Cherubino in women's clothes, “Venite, inginocchiatevi“, flirting with him all the while.</p>

            <VideoPlayer src="https://www.youtube.com/embed/8ulPPYTQPUQ?autoplay=1" id="8ulPPYTQPUQ" title="Diana Damrau's Susanna dresses Monica Bacelli's Cherubino from La Scala in 2006"/>

            <p>Susanna leaves, and the Countess finds her ribbon, which Cherubino stole from Susanna in Act I. There is some fretting over a cut he has and his pale skin and his tears almost win the Countess's affection when her husband knocks on the door demanding entry. Terrified, they hide Cherubino in the walk-in closet and lock the door. The Count enters and thrusts Figaro's forged note at his wife. Cherubino panics and makes a clatter in the closet.</p>

            <p>From here on out, the end of the act is fairly madcap, with Mozart driving the action into an ever-growing tempest. A farcical “who's in the closet“ begins, with the Countess saying it's Susanna and the Count saying, why would that make you nervous. Susanna herself returns quietly but stops when she sees the pounding on the closet door, and a wonderful trio “Susanna, or via sortite“ relates their pleas and worries. The Count and Countess leave to get tools to break down the closet door, locking Susanna in the room where Cherubino is locked in the closet.</p>

            <p>The terrified youth opens the closet, and he and Susanna realize he has to be gone by the time the Count and Countess return. With melodrama appropriate to his youth, Cherubino leaps out the window despite Susanna's protestations. She then hides in the closet, closes the door, and waits. The Count and Countess return, and he is about to force the door when the Countess says Cherubino in the closet! And even worse, he is partially undressed. She is telling the truth, but the Count is outraged, growing increasingly rude, while the Countess protests she is innocent of indiscretion. The Count draws his sword to kill Cherubino, opens the door, and Susanna walks out to everyone's surprise!</p>

            <p>She confidentially assures the Countess that Cherubino is safe, then the ladies turn on the Count, saying he deserves no pity for his rage. The Countess, dripping with sarcasm, insists she doesn't deserve love then grows genuinely angry at the Count's treatment of her. Their trio turns to a quartet as Figaro appears, then a quintet as Antonio, the gardener, busts in and says he saw a young man thrown from the balcony moments ago! Figaro, Susanna, and the Countess accuse Antonio of being drunk; the Count asks him repeated questions; and Susanna and the Countess quietly explain to Figaro all that has happened.</p>

            <p>Incredibly, the scene grows even more chaotic as Marcellina, Bartolo, and Basilio show up, ready to annul Figaro's engagement because he hasn't repaid his loan. The combatants choose upsides, with Susanna, the Countess, and Figaro confused and cursing their plight; and Marcellina, Basilio, Bartolo, and the Count sensing victory!</p>

            <VideoPlayer src="https://www.youtube.com/embed/ehaFeSX1g2U?autoplay=1" id="ehaFeSX1g2U" title="Ildebrando d'Arcangelo (Figaro), Anna Netrebko (Susanna), Bo Skovhus (Count), Dorothea Röschmann (Countess), Christine Schäfer (Cherubino) in the Act 2 finale from the Salzburg Festival in 2006"/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-106"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act III</SynopsisAct>

            <p>The act opens with the Count recapping the action of the previous finale and trying to suss out if he is being deceived. Susanna and the Countess enter and agree that the Countess will dress as Susanna to meet her husband in the garden. The Count sees them and worries Susanna is telling his wife about his amorous intentions, and when the Countess leaves, he goes to Susanna. In the duet “Crudel! Perché finora farmi languir così?“, she agrees to meet him that evening.</p>

            <p>They each are pleased with the state of affairs after this duet, but it is short-lived as Figaro enters and, when he and Susanna are leaving, she tells him everything is all set. But the Count overhears and knows he has been duped! (“Hai già vinto la causa!“) He vows that Figaro will marry Marcellina.</p>

            <VideoPlayer src="https://www.youtube.com/embed/WARonxjhQxE?autoplay=1" id="WARonxjhQxE" title="The late, great, Russian baritone Dmitri Hvorostovsky sings the Count's aria “Hai già vinto la causa!“"/>

            <p>At some point, in some productions, Cherubino and Barbarina may come on stage briefly: Basically, she says she is going to take him to her house and dress him up like a girl so he can join her and her friends as they bring flowers to the Countess.</p>

            <p>The scene changes to the trial over Marcellina's loan. With the Count presiding, she and Bartolo present the contract. But Figaro says he can't marry someone without his parents’ permission. The Count says let's see these parents, and Figaro replies that he has been looking for them for ten years. All he knows is he was stolen as a child, and he has a mysterious birthmark. Marcellina says, birthmark?! Like a spatula! Long story short, Figaro is Marcellina and Bartolo's long lost son. Seriously.</p>

            <p>This revelation leads to the joyous, comic sextet “Riconosci in quest'amplesso una madre“, which includes Susanna entering during the many tearful reunions and seeing Figaro hugging Marcellina—his mother. She assumes he has abandoned her, becomes furious, smacks him, and finally decides to listen to her soon to be mother-in-law, leading to a whole bunch of your mom???!!/your dad???!! Bartolo and Marcellina decide that they will get married at the same time as their son and Susanna. It is all glorious and was apparently Mozart's favourite piece in the whole opera.</p>

            <p>Everyone leaves, and the Countess comes on, alone, to sing another absolute gem, “Dove sono“, about how she misses the beautiful moments of the past and still hopes to win back her husband's love.</p>

            <VideoPlayer src="https://www.youtube.com/embed/LWy97arDeXk?autoplay=1" id="LWy97arDeXk" title="Leontyne Price sings “Dove sono“ at a Metropolitan Opera gala in 1972, culminating in the sort of rapturous applause that is rarely heard in opera houses these days."/>

            <p>The rest of the act is set up for the final machinations. Antonio tells the Count that Cherubino is back. Susanna and the Countess iron out their deceit for the evening. Susanna writes a letter to the Count, as dictated by the Countess. They close it with a pin, which the Count should return to acknowledge he has received the letter.</p>

            <p>Barbarina and the peasant girls, including Cherubino, come in. The Countess asks for Cherubino's flowers, but Antonio is onto him, causing the Count to grow suspicious about his wife and Cherubino again. Barbarina throws the Count under the bus, however, by saying, “Remember when you were making out with me, you said I could have whatever I want, I want Cherubino”.</p>

            <p>The act ends with a wedding march playing and townsfolk arriving to celebrate. Susanna, Figaro, Marcellina, and Bartolo are all presented to the Count and Countess, and Susanna slips her note to the Count. Everyone dances, and the Count opens the letter and pricks his finger on the pin. The chorus continues to sing about faithful lovers.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-107"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act IV</SynopsisAct>

            <p>As the deception and incorrect assumptions cascade in the final act, the plot grows pretty complicated. Mozart keeps it all together with some of opera's most memorable arias and a couple that are regularly cut—and were only included originally because every major character had to have their aria in the 1700s (whether the narrative required it or not!).</p>

            <p>It all begins at night, with Barbarina briefly lamenting the loss, “L'ho perduta“, of the Count's pin that she has been ordered to return to Susanna. Figaro arrives with his mom, and Barbarina tells them what has happened. Figaro, not knowing the scheme hatched by Susanna and the Countess, immediately jumps to the wrong conclusion and swears “to avenge all husbands!“ He storms off, and Marcellina decides to warn Susanna. (Her aria is the first that is often omitted.)</p>

            <p>Basilio and Bartolo meet with Figaro, who has enlisted them in his ill-conceived plot to entrap his bride. They are confused by the whole thing, with Bartolo saying essentially accept infidelity, Figaro, and Basilio singing about his experiences. (His aria is also frequently skipped on stage.)</p>

            <p>Figaro, alone now, rants about how awful women are and how they should never be trusted. Mozart significantly toned down the original monologue from the play, but Figaro still comes off as an ass, despite it being a fun aria.</p>

            <p>Figaro hides, and Susanna and the Countess enter wearing each other's clothes. Marcellina is with them, and it is clear they have been discussing Figaro's plan. Everyone hides except Susanna, who decides to mess with Figaro by singing about how excited she is for the night and her true love, who is hidden in the garden. “Deh, vieni, non tardar“ is all about Figaro, but he thinks it is about the soon-to-arrive Count.</p>

            <VideoPlayer src="https://www.youtube.com/embed/F1HuCWVQ-vs?autoplay=1" id="F1HuCWVQ-vs" title="Anna Netrebko singing “Deh, vieni, non tardar“ at Salzburg in 2006, complete with a pretty nice decrescendo while being lifted into the air."/>

            <p>For the finale, it is crucial to understand that it is nighttime, so even though the audience clearly sees who everyone is, the endless misidentification can be blamed on the darkness.</p>

            <p>It starts with Cherubino arriving in search of Barbarina. He sees “Susanna”, really the Countess, and decides to flirt with her, but she tries to get rid of him. The Count comes in and sees what's going on, and just as Cherubino tries to kiss “Susanna”, the Count steps in, and Cherubino kisses him. The Count goes to slap him, but the youth has fled and Figaro has stepped in and gets slapped instead.</p>

            <p>Figaro and “the Countess” hide, and the Count begins to woo “Susanna”, who is actually his wife. He gives her a ring, and she goes to wait for him in private. Figaro, sneaking around, finds “the Countess” and tells her they have their spouses right where they want them. Susanna forgets to disguise her voice, and Figaro realizes what is happening but doesn't admit it. Now he wants to pay her back, and she wants to punish him. So he starts to romance her, acting as if she is “the Countess”, and finally Susanna can't take it any more and slaps him and he smiles, and they both realize the other was in on it and it’s fine, “Pace, pace, mio dolce tesoro!“</p>

            <p>The Count comes upon them and is furious, thinking Figaro has just made love to “the Countess”. He calls everyone in to see the betrayal of his wife, and Basilio, Antonio, Bartolo, Cherubino, Barbarina, and Marcellina all enter. Despite her protestations, the Count refuses to forgive “the Countess“, who is really Susanna.</p>

            <p>Then the real Countess strides on, and boy does the Count feel like an idiot. He sings a truly heartfelt “Contessa perdono“ to which she replies with the incomparably beautiful “Più docile sono, e dico di sì.“ I am more forgiving than you. It is heartbreaking, and Mozart backs it with strings and winds, and slowly, everyone joins in. The music gradually soars to a delirious celebration.</p>

            <VideoPlayer src="https://www.youtube.com/embed/KmTcYiuwYCE?autoplay=1" id="KmTcYiuwYCE" title="Robin Ticciati leads the Orchestra of the Age of Enlightenment and the Glyndebourne Chorus, with Sally Matthews and Audun Iverson, in 2012."/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-108"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Where and When"/>

            <p>The whole opera is set in a single location: outside Seville, Spain, at Aguas-Frescas, the country home/castle of the Count and Countess Almaviva. The action compactly takes place across one day.</p>
            
            <Header title="History"/>

            <p>Mozart wrote the music for <i>Figaro</i> in two sessions between the fall of 1785 and the spring of 1786. The opera had initially been set to open around Christmas 1785 but got pushed back to May. The libretto, written by Lorenzo Da Ponte in the first of his three historic collaborations with Mozart, is based on a French play by Pierre Beaumarchais called <i>The Crazy Day, or The Marriage of Figaro</i>. The play, the second part of a trilogy, was initially banned in Paris due to its portrayal of the abuses of the aristocracy. (The French Revolution was not for another fifteen years or so, but the ruling class was already a bit on edge given all the Enlightenment going on. Napoleon would later refer to the play as the first shot of the revolution.)</p>

            <p>The play was still banned in Vienna when Da Ponte proposed it to Emperor Joseph. In fact Joseph had just nixed a stage production led by Emanuel Schikaneder, the eventual librettist for <em><Link to="/operas/flute">Die Zauberflöte</Link></em>. Joseph eventually approved the project, partly because Mozart was Mozart and partly because Da Ponte promised to dial back the politics.</p>

            <p>The premiere took place May 1, 1786, at the Burgtheater. The Count was Stefano Mandini, who had sung the same role in the prequel opera, <i>The Barber of Seville</i> (see Fun Facts: Paisiello's prequel). Francesco Benucci, who sang Bartolo in the prequel, sang Figaro for Mozart. Nancy Storace, an English soprano who was a sensation during her brief time in Vienna, sang Susanna. Francesco Bussani sang Bartolo, and his wife, Dorothea, sang Cherubino. Anna Gotlieb, who was only 12, sang Barbarina.</p>
        
          </MainBodySection>

            <div id="ezoic-pub-ad-placeholder-109"> </div>
  
            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Fun Facts"/>

            <FunFactsHeader>A truly operatic librettist</FunFactsHeader>

            <ImageRight maxwidth="300px">
              <StaticImage src="../../images/operas/figaro/Lorenzo_da_Ponte.jpg" alt="Lorenzo Da Ponte Engraving by Michele Pekenino after Nathaniel Rogers"/>
              <figcaption>Lorenzo Da Ponte Engraving by Michele Pekenino after Nathaniel Rogers</figcaption>
            </ImageRight>

            <p>Mozart and Lorenzo Da Ponte collaborated on three operas that would cement Da Ponte's reputation as one of the greatest librettists in opera history. But Da Ponte's own life seemed like something out of the theatre as well.</p>

            <p>He was born outside Venice and abandoned his seminary education for a married woman in the city. He became friends with Casanova, wrote poetry, and embarked on a lifetime of serial affairs, partying, gambling, and drinking. Eventually, his excesses—and his blasphemy in the eyes of the Inquisition—got him banished from the city. Still, his ability for self-promotion, perhaps dishonestly, landed him on his feet in Dresden, where he worked for a successful librettist. When Da Ponte arrived in Vienna in 1781, he still had never written a libretto himself. Yet a year or so later, Emperor Joseph named him court librettist. His philandering didn't stop with his new job, even leading to the loss of most of his teeth after Da Ponte stole the beloved of a doctor who later prescribed him an oral solution with nitric acid in it.</p>

            <p>When Mozart approached Da Ponte with Beaumarchais' play, Da Ponte had written just four librettos, including one for Salieri that was such a failure, Salieri swore to never work with Da Ponte again. Thankfully for opera lovers, Mozart made his own decision.</p>

            <FunFactsHeader>Standard opera-ting procedures</FunFactsHeader>

            <p>It was commonplace in the 18th century for composers to write new arias for specific performances or performers. Mozart wrote substitute arias for several singers, including his sister-in-law (and first love) Aloysia Lange née Weber. In 1783, Aloysia had a new rival in town and wanted to set the bar, so she got Mozart to write two arias for her performance in Pasquale Afossi's <i>The Imprudent Curious Man</i>. (The arias were so good, and the rest of the opera so bad, they caused a scandal in Vienna.) The new rival was Nancy Storace, who three years later became the first Susanna in <i>Figaro</i>.</p>

            <p>When <i>Figaro</i> was revived in Vienna in 1789, Storace was replaced by Adriana Ferrarese del Bene, Da Ponte's lover du jour. Mozart wrote two new arias for Ferrarese del Bene, replacing Susanna's cavatina brilliance “Venite ingionocchiatevi“ (when she and the Countess are dressing—and undressing—Cherubino) and the standout “Deh vieni non tardar“ (her Act IV declaration of love for Figaro). Those revival performances were a hit, but it took almost 200 years until NYC audiences could hear a <i>Figaro</i> with the substitute arias. The Italian mezzo Cecilia Bartoli sang them at three performances during a November 1998 run at the Met. One reviewer called the second substitution “egocentric agitation [in lieu of] magical serenity“.</p>

          </MainBodySection>

            <div id="ezoic-pub-ad-placeholder-110"> </div>
  
            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <FunFactsHeader>Alternative arias</FunFactsHeader>

            <p>Two Viennese opera traditions fell victim to <i>Le nozze di Figaro</i>. The first originated in Da Ponte's memoirs, so it may be apocryphal, and gained common purchase in Milos Forman's Oscar-winning film “Amadeus“. It has the court, in the person of Count Rosenberg, removing the ballet from the third act score because of a prohibition against ballet in opera. Da Ponte and Mozart made sure that Emperor Joseph showed up at rehearsal, and when he did, he was confused as to why the singers just stood there during the ballet music. (In the film, Joseph memorably exclaims, “LOOK at them!“) The ballet was promptly reinstated.</p>

            <p>The second change was a direct result of the power of Mozart's music. *Figaro*’s premiere generated five encores; the third performance led to seven, plus an encore of a duet. This last was too much for Emperor Joseph, who liked his evenings to move along, so he banned encores of anything except solo arias.</p>

            <FunFactsHeader>Simply the best</FunFactsHeader>

            <p>While the most often performed opera in the world is <em><Link to="/operas/traviata">La Traviata</Link></em>, and the most often performed Mozart opera is <em><Link to="/operas/flute">Die Zauberflöte</Link></em>, neither of those operas are the “greatest opera“, at least according to one poll conducted by the BBC Music Magazine in 2017. But this wasn't a clickbait Twitter poll or a survey of random folk out for a pint; this was a poll of 172 of the world's top opera singers. And it wasn't close. *Figaro* got more than twice the votes of the second-place opera, <Link to='/operas/boheme'>La bohème</Link>.</p>

            <FunFactsHeader>Paisiello’s prequel</FunFactsHeader>

            <p>History may have rightfully seen the value in Mozart's <i>Figaro</i>, but during his lifetime, the opera played second fiddle to its prequel, Giovanni Paisiello's <i>Il barbiere di Siviglia</i>, based on the first play of Beaumarchais' trilogy. Paisiello's is not the opera version of <i>Barbiere</i> known to audiences today. That <i>barbiere di Siviglia</i> was composed by Gioachino Rossini and didn’t premiere until 1816. Mozart's <i>Figaro</i> got nine performances during its initial run in Vienna, but Paisiello's <i>Barbiere</i> had been done 38 times in the years leading up to Mozart's premiere and would remain the favourite of the Viennese during most of Mozart's life.</p>

            <Header title="Bibliography"/>

            <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default FigaroPage
